import React from 'react'
import { useField } from 'formik'
import { InputFieldAttrProps, InputField } from '@components/Form/InputField'

export const FormikInputField: React.FC<InputFieldAttrProps> = ({
  name,
  ...rest
}) => {
  const [field, meta] = useField({
    name: name,
  })

  return (
    <InputField
      {...rest}
      {...field}
      $status={{
        ...rest.$status,
        error: (meta.touched && meta.error) || undefined,
      }}
    />
  )
}
