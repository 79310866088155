import React from 'react'
import { useField } from 'formik'
import { InputSmartCreatableSelect } from '@components/Form/InputSmartSelect/Creatable'
import { EmailReceiptStyled } from '@features/nbee/EmailReceipt/styled'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { useTranslation } from 'react-i18next'

interface Props {
  name: string
  disabled?: boolean
}

const { FieldGroup, FieldRow } = EmailReceiptStyled

export const FieldRecipients: React.VFC<Props> = ({ name, disabled }) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField<string[]>(name)

  return (
    <FieldGroup>
      <FieldRow>
        <label>{t('nbee.emailReceipt.labelReceivers')}</label>
        <InputSmartCreatableSelect
          isDisabled={disabled}
          placeholder={t('nbee.emailReceipt.placeholderReceivers')}
          isClearable={false}
          defaultValue={field.value}
          onSelect={(values) => {
            helpers.setValue(values)
          }}
          onBlur={() => {
            helpers.setTouched(true)
          }}
        />
      </FieldRow>

      <FieldRow>
        <div />
        {meta.touched && meta.error ? (
          <InputFeedback
            $status={{
              error: Array.isArray(meta.error)
                ? meta.error.filter((msg) => !!msg).join(', ')
                : meta.error,
            }}
          />
        ) : null}
      </FieldRow>
    </FieldGroup>
  )
}
