import React, { useState } from 'react'
import { InputField } from '@components/Form/InputField'
import { ApiAppSetting } from 'BackendApi'
import { AppConfigurationType, BridgeFormValues } from 'Nbee'
import { useFormikContext } from 'formik'
import { getSettingFieldError } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/utils'
import { Textarea } from '@components/Form/Textarea'
import { LabelWithDocTooltip } from '@components/Form/LabelWithDocTooltip'
import { useTriggerSettingsUpdate } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/useTriggerSettingsUpdate'
import { trackEvent } from '@app/dataTracking'

interface Props {
  fieldSchema: ApiAppSetting
  type: AppConfigurationType
  isLoading: boolean
  index: number
}

export const SettingFieldInput: React.FC<Props> = ({
  fieldSchema,
  isLoading,
  type,
  index,
}) => {
  const {
    values: formValues,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext<BridgeFormValues>()

  const { refetchSettings } = useTriggerSettingsUpdate(type)

  const settingFieldName = `${type}.settings`
  const currentSettingsValues = formValues[type].settings || []

  const fieldId = fieldSchema.id
  const fieldType = fieldSchema.type

  const currentFieldValue = currentSettingsValues.find((v) => v.key === fieldId)

  // if we hare here value can't be boolean, so it's safe to cast
  const initialValue = currentFieldValue
    ? (currentFieldValue.value as string | number)
    : ''

  const [internalInputValue, setInternalInputValue] = useState(
    initialValue === 0 ? '' : initialValue
  )

  const { isTouched, fieldStatus } = getSettingFieldError({
    type: type,
    touched: touched,
    errors: errors,
    index: index,
  })

  const onBlurUpdateFormikState = () => {
    // if formik value is equal to internalInputValue we can skip the update
    const wasEmptyIsStillEmpty = !currentFieldValue && !internalInputValue
    const wasFilledStillHasSameValue =
      currentFieldValue && currentFieldValue.value === internalInputValue
    const skipUpdate = wasEmptyIsStillEmpty || wasFilledStillHasSameValue
    if (skipUpdate) {
      return
    }

    // if new value is empty, we need to remove the key from formikState
    if (!internalInputValue) {
      // removing from formik state if no value is added
      const newSettings = currentSettingsValues.filter(
        (setting) => setting.key !== fieldId
      )
      setFieldValue(settingFieldName, newSettings)
      if (fieldSchema.hasChild) {
        refetchSettings()
      }
      return
    }

    const parsedValue =
      internalInputValue && fieldType === 'number'
        ? parseInt(`${internalInputValue}`, 10)
        : internalInputValue

    const newValue = {
      key: fieldId,
      value: parsedValue,
    }
    const newSettings = currentFieldValue
      ? currentSettingsValues.map((setting) =>
          setting.key === fieldId ? newValue : setting
        )
      : [...currentSettingsValues, newValue]

    setFieldValue(settingFieldName, newSettings)

    trackEvent({
      eventName: 'IntegrationSettingSelected',
      step: 'Apps',
      feature: 'NBEE',
      params: {
        custom: {
          fieldId: fieldId,
          value: parsedValue,
        },
      },
    })

    // we trigger the retrival of new setting only if is a setting with a child
    if (fieldSchema.hasChild) {
      refetchSettings()
    }
  }

  return (
    <div>
      <LabelWithDocTooltip
        label={fieldSchema.label}
        isRequired={fieldSchema.required}
        tooltip={fieldSchema.tooltip}
        docUrl={fieldSchema.docUrl}
      />
      {fieldType === 'textarea' || fieldType === 'code' ? (
        <Textarea
          disabled={isLoading || formValues.ui?.isBridgeEnabled}
          id={fieldId}
          name={fieldId}
          value={internalInputValue}
          onChange={({ target: { value } }) => {
            setInternalInputValue(value)
          }}
          onBlur={() => {
            setFieldTouched(`${settingFieldName}.${index}`, true)
            onBlurUpdateFormikState()
          }}
          $status={isTouched ? fieldStatus : undefined}
        />
      ) : (
        <InputField
          type={
            fieldType === 'password'
              ? 'password'
              : fieldType === 'email'
              ? 'email'
              : fieldType === 'url'
              ? 'url'
              : fieldType === 'number'
              ? 'number'
              : 'text'
          }
          disabled={isLoading || formValues.ui?.isBridgeEnabled}
          id={fieldId}
          autoComplete={fieldType === 'password' ? 'new-password' : 'off'}
          name={fieldId}
          value={internalInputValue}
          onChange={({ target: { value } }) => {
            setInternalInputValue(value)
          }}
          onBlur={() => {
            setFieldTouched(`${settingFieldName}.${index}`, true)
            onBlurUpdateFormikState()
          }}
          onKeyPress={(event) => {
            // we trigger formik change on enter key press, we do this so we can trigger validation while submit button is disabled
            if (event.key === 'Enter') {
              setFieldTouched(`${settingFieldName}.${index}`, true)
              onBlurUpdateFormikState()
            }
          }}
          $status={isTouched ? fieldStatus : undefined}
        />
      )}
    </div>
  )
}
