import React from 'react'
import styled from 'styled-components'

export const KebabButtonStyled = styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;

  svg {
    height: 1.2rem;
    fill: #b7b7b7;
  }
`

export const KebabButton: React.FC = () => (
  <KebabButtonStyled type={'button'} data-testid='kebab-button-styled'>
    <svg
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <path d='M16 10c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3zM16 13c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM16 22c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z' />
    </svg>
  </KebabButtonStyled>
)
