import { useContext, useEffect, useState } from 'react'
import { useCheckAppsCompatibility } from '@app/api/checkAppsCompatibility'
import { getAppCompatibilityPopupContent } from './utils'
import { useFormikContext } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { PanelPopupContext } from '@components/Panel'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { bridgeBuilderStep1Paths, bridgeByUrlPartnersPaths } from '@app/routes'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { parseApiError } from '@app/api/utils/error'

// This hook only works when formik context of type `BridgeFormValues` is available
// it will read the selected source and destination apps id and will show a popup and prevent the submit
// if apps are not compatibile or if user needs to switch them or upgrade plan
export const useHandleAppsCompatibility = () => {
  const popupCtx = useContext(PanelPopupContext)
  const formikProps = useFormikContext<BridgeFormValues>()
  const { t } = useTranslation()
  const [appsAreCompatible, setAppsAreCompatible] = useState(false)

  // we need this to proper build the analytics track event
  const location = useLocation()

  const step1Paths = [...bridgeBuilderStep1Paths, ...bridgeByUrlPartnersPaths]

  const isInStep1 = step1Paths.includes(location.pathname)

  const sourceId = formikProps?.values.source?.appId
  const destinationId = formikProps?.values.destination?.appId

  const [emailFeatureAvailable, setEmailFeatureAvailable] =
    useState<boolean>(false)

  // checking apps compatibility when source + destination is selected
  const {
    data: appCompatibilityCheck,
    isLoading: isCheckingAppsCompatibility,
    isRefetching: isReCheckingAppsCompatibility,
    error: apiError,
  } = useCheckAppsCompatibility(sourceId, destinationId)

  // if apps are note compatible we must inform the user
  useEffect(() => {
    if (
      isCheckingAppsCompatibility &&
      isReCheckingAppsCompatibility &&
      popupCtx?.sendPopup
    ) {
      formikProps.setSubmitting(true)
      popupCtx.sendPopup(undefined)
    }

    if (appCompatibilityCheck) {
      // we build popup content depending on result and set  status of popup item
      // only if we have content that has been handled inside getAppCompatibilityPopupContent
      // otherwise popup can be cleared
      const content = getAppCompatibilityPopupContent(
        appCompatibilityCheck,
        formikProps,
        t,
        {
          isInStep1: isInStep1,
        }
      )

      const isNotCompatible =
        appCompatibilityCheck?.combination.compatibility === 'none'

      if (popupCtx?.sendPopup && content) {
        // it means we have some compatibility error
        formikProps.setSubmitting(true)
        setAppsAreCompatible(false)
        popupCtx.sendPopup({
          content: content,
          position: 'bottom',
          dismissable: isNotCompatible,
          onClose: () => {
            // we want to remove src and dest when the user closes the popup, so it can choose new apps
            // using `resetForm` utils from Formik is the best way to re-set the initialState
            if (isNotCompatible) {
              formikProps.resetForm()
            }
          },
        })
      } else {
        // apps are compatibile
        formikProps.setSubmitting(false)
        setAppsAreCompatible(true)

        setEmailFeatureAvailable(
          appCompatibilityCheck?.combination?.availableBridgeFeatures?.some(
            (feature) =>
              feature.moduleId === 'email-receipt' && feature.available
          )
        )
      }
    }
  }, [
    appCompatibilityCheck,
    isCheckingAppsCompatibility,
    isReCheckingAppsCompatibility,
  ])

  useEffect(() => {
    if (apiError) {
      const parsedError = parseApiError(apiError)
      trackEvent({
        eventName: 'UnexpectedErrorThrown',
        feature: 'NBEE',
        step: isInStep1 ? 'Apps' : 'BridgeChooser',
        params: {
          ...makeNbeeTrackingParams(formikProps?.values),
          custom: {
            errorCode: parsedError.code,
            errorDescription: parsedError.message,
          },
        },
      })
    }
  }, [apiError])

  // when source or destination changes, we need to remove the popup (in case is open)
  useEffect(() => {
    // const appsNotSelected = !sourceId || !destinationId
    const popupIsOpen = popupCtx?.popup?.content
    if (popupIsOpen) {
      popupCtx.sendPopup!(undefined)
    }
  }, [sourceId, destinationId])

  return {
    appsAreCompatible: appsAreCompatible,
    emailFeatureAvailable: emailFeatureAvailable,
  }
}
