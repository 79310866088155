import React, { FC, ReactNode } from 'react'
import { createGlobalStyle } from 'styled-components'
import { BrandColors } from './Variables'

interface TypographyProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  tagName?: keyof JSX.IntrinsicElements
  className?: string
  children?: ReactNode
}
const GlobalTypographyStyled = createGlobalStyle`
  
  /*H1 Headline*/
  .text-block-1{
    line-height: 1.4;
    font-size: 27px;
    font-weight: bold;
  }
  
  /*H2 Headline*/
  .text-block-2{
    line-height: 1.3;
    font-size: 18px;
    font-weight: bold;
  }
  
  /*H3 Headline*/
  .text-block-3{
    text-transform: uppercase;
    line-height: 1.3;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  /*Subtitle*/
  .text-block-4{
    line-height: 1.5;
    font-size: 14px;
    font-weight: bold;
  }
  /* used for subheader */
  .text-block-5{
    line-height: 1.3;
    font-size: 20px;
  }
  .text-block-6{
    line-height: 1;
    font-size: small;
    margin:0;
    letter-spacing: 4px;
    text-transform: uppercase;
  }
  /*Parag*/
  .text-block{
    font-size: 1rem;
    line-height: 1.4;
    font-weight: normal;
  }
  .text-block.bold{
    font-weight: bold;
  }
  .text-block.small{
    font-size: 12px;
    line-height: 1.3;
  }
  [class^='text-block-']{}
  
  .text-secondary{
    color: ${BrandColors.secondary};
  }
  .text-bold{
    font-weight: bold;
  }
  .text-link{
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .text-black{
    color: ${BrandColors.default};
  }
`

const Typography: FC<TypographyProps> = ({ tagName, ...otherProps }) => {
  const Tag = tagName as keyof JSX.IntrinsicElements
  let sizing = ''
  switch (Tag) {
    case 'h1':
      sizing = 'text-block-1'
      break
    case 'h2':
      sizing = 'text-block-2'
      break
    case 'h3':
      sizing = 'text-block-3'
      break
    case 'h4':
      sizing = 'text-block-4'
      break
    case 'h5':
      sizing = 'text-block-5'
      break
    case 'h6':
      sizing = 'text-block-6'
      break
    default:
      sizing = 'text-block'
      break
  }
  return (
    <>
      <GlobalTypographyStyled />
      <Tag {...otherProps} className={`${otherProps.className} ${sizing}`} />
    </>
  )
}

Typography.defaultProps = {
  tagName: 'p',
}
export default Typography
