import styled, { css } from 'styled-components'
import { mediaIframeOnly } from '@app/styles/iframeUtils'

export const SignInForm = styled.div`
  height: 100%;
  padding: 1em;

  .sign-in-form {
    &__wrapper {
      width: 100%;
      min-height: 600px;
      padding: 1rem 2rem 0.5rem 2rem;
      background: #ffffff;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 20%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (min-width: 721px) {
        min-width: 423px;
      }
      @media screen and (max-width: 720px) {
        padding: 0;
        min-height: auto;
        box-shadow: none;
      }

      ${mediaIframeOnly(css`
        padding: 0 2rem;
      `)}
    }
    &__head {
      width: 100%;
      img {
        margin: 1rem auto;
      }
    }
    &__body {
      width: 100%;
      .my-2 {
        margin-top: 1em;
      }
    }
    &__footer {
      margin-top: 1rem;
      & p {
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          text-decoration: underline;
          opacity: 1;
        }
      }
      .ui.grid > .column:not(.row),
      .ui.grid > .row > .column {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }
  }
`
