import { BridgeFormValues, MappedField } from 'Nbee'
import { ApiBridgeFieldsListResponse } from 'BackendApi'
import { useEffect } from 'react'
import { sendAlertMessage } from '@app/store/actions/ApplicationConfigurationActions'
import { useAppDispatch } from '@app/store/hooks'
import { useTranslation } from 'react-i18next'
import { encodeToBase64 } from '@app/utils/base64'

export interface FieldMappingMatched {
  initialValues: BridgeFormValues | null
  notFoundFields: {
    destination: string[]
    source: string[]
  }
}

// This function returns a list of all destination fields to map filled with already mapped (existing in case of edit)
// or auto mapped values. This list is used as `initialValues` for the fields mapping form.
// It also checks if some previously mapped fields do not exists anymore in source or destination
// and fill an array of missing field ids (notFoundFields.destination and notFoundFields.source)
// The missing values are removed from the initialValues, this means that our initialValues should never includes not existing values
export const makeFieldsMappingInitialValuesAndListNotMatchingFields = (
  bridgeFormValues?: BridgeFormValues,
  allBridgeFields?: ApiBridgeFieldsListResponse
): FieldMappingMatched => {
  const alreadyMappedFields = bridgeFormValues?.fieldsMapping || []
  const autoMappedFields = allBridgeFields?.data?.autoMapping || []
  const allFieldsToMap = allBridgeFields?.data?.destination || []

  const allFieldsValues = allBridgeFields?.data
  const alreadyMappedDestinationValues = bridgeFormValues?.fieldsMapping || []

  // We need to check if fields still exist in the bridge fieldsMapping array
  // in destination
  const mappedDestinationFielsNotAvailable = alreadyMappedDestinationValues
    .filter(
      (value) =>
        !allFieldsValues?.destination.find(
          (field) => field.id === value.destinationFieldId
        )
    )
    .map((el) => el.destinationFieldId)
  // in source
  const mappedSourceFieldsNotAvailable = alreadyMappedDestinationValues
    .filter((el) => el.destinationFieldType === 'source') // we only filter in values set as source
    .filter(
      (value) =>
        !allFieldsValues?.source.find(
          (field) => field.id === value.sourceFieldId
        )
    )
    .map((el) => el.sourceFieldId || '')

  // we fill an array that will be passed as fieldsMapping value in form state
  const filledFieldsMappingValues: MappedField[] = allFieldsToMap.map(
    (field) => {
      const existingValue = alreadyMappedFields.find(
        (o) => o.destinationFieldId === field.id
      )
      const autoMapped = autoMappedFields.find(
        (o) => o.destinationFieldId === field.id
      )

      const extraDataForUi = {
        destinationFieldLabel: field.label,
        isRequired: field.required || false,
        testLeadValue: '',
        picklist:
          field.values?.sort((a, b) => a.text.localeCompare(b.text)) || [],
      }

      // if mapping for this field is already saved in the bridge we use this
      if (existingValue) {
        return {
          ...existingValue,
          ...extraDataForUi,
        }
      }

      // if data is found in the autoMapping array we use this
      if (autoMapped) {
        return {
          ...autoMapped,
          mappingType: 'auto',
          ...extraDataForUi,
        }
      }

      // return default empty values
      return {
        destinationFieldId: field.id,
        destinationFieldType: field.values?.length ? 'custom' : 'source', // INFO: we set custom type as default in case we have picklist values
        sourceFieldId: '',
        mappingType: 'manual',
        destinationText: '',
        ...extraDataForUi,
        formula: null,
      }
    }
  )

  // we clear the fieldsMapping values by removing all non found source fields
  const clearedFieldMapping = filledFieldsMappingValues.map((mappedField) =>
    mappedSourceFieldsNotAvailable.includes(mappedField.sourceFieldId || '')
      ? {
          ...mappedField,
          sourceFieldId: '',
        }
      : mappedField
  )

  return {
    initialValues: bridgeFormValues
      ? {
          ...bridgeFormValues,
          fieldsMapping: clearedFieldMapping,
        }
      : null,
    notFoundFields: {
      destination: mappedDestinationFielsNotAvailable,
      source: mappedSourceFieldsNotAvailable,
    },
  }
}

// This hook is used to dispatch an alert message when `notFoundFields` has data
export const useNotifyUserOfMissingMappedFields = (
  fieldsMappingMatched: FieldMappingMatched | null,
  bridgeId: number | string
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // we make one single array, at this stage we show one generic message that includes both source and destination missing field
  // In future we can work with UX to generate a better message and notify if missing fields are relative to source or destination (or both)
  const notFoundPreviouslyMappedFieldsDestination =
    fieldsMappingMatched?.notFoundFields.destination || []
  const notFoundPreviouslyMappedFieldsSource =
    fieldsMappingMatched?.notFoundFields.source || []
  const notFoundPreviouslyMappedFields = [
    ...notFoundPreviouslyMappedFieldsDestination,
    ...notFoundPreviouslyMappedFieldsSource,
  ]

  // this is a workaround: the alert pops-up too often because every time a field refresh is called (inside the form)
  // a new notFoundPreviouslyMappedFields is generated, and since this also sets initialValues can't be memoized.
  // So we store user action when alert is closed
  const encodedFields = encodeToBase64(notFoundPreviouslyMappedFields)
  const persistentKey = `missingFieldsNotification:${bridgeId}`
  const persistentEncodedFields = localStorage.getItem(persistentKey)
  const alreadyNotified = persistentEncodedFields === encodedFields

  useEffect(() => {
    if (
      notFoundPreviouslyMappedFields &&
      notFoundPreviouslyMappedFields.length &&
      !alreadyNotified
    ) {
      dispatch(
        sendAlertMessage({
          isDismissable: true,
          message: t('nbee.fieldsMapping.missingMappedFields', {
            listOfNotFoundIds: notFoundPreviouslyMappedFields.join(', '),
          }),
          onClose: () => {
            localStorage.setItem(persistentKey, encodedFields)
          },
        })
      )
    }
  }, [notFoundPreviouslyMappedFields])
}
