import React, { useEffect, useState } from 'react'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { IntegrationFormField, IntegrationFormValues } from 'Nbee'
import { useField, useFormikContext } from 'formik'
import { Loader } from '@components/Basic/Loader'
import { useTranslation } from 'react-i18next'
import { Button } from '@components/Basic/ButtonNbe'
import { InputWrapper } from '@features/nbee/IntegrationCredentialsContent/styled'
import { useSendCode } from '@app/api/postSendCode'
import { ApiUserVerifiedEmailsObj } from '@app/@typings/Api/user'
import { Modal } from '@components/Basic/Modal'
import styled from 'styled-components'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useVerifyCode } from '@app/api/postVerifyCode'

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ModalInnerWrapper = styled.div`
  margin: 0 auto;
  line-height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  text-align: center;
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;

  .ReactInputVerificationCode__item {
    position: relative;
    color: #22a7f0;
    font-weight: 800;
    // box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    // box-shadow: none;
  }

  .ReactInputVerificationCode__item:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 3px;
    // background-color: #ebebeb;
    transition: background-color 0.2s ease-out;
  }

  .ReactInputVerificationCode__item.is-active:after {
    background-color: #046cde;
  }
`

interface Props {
  index: number
  isLoading?: boolean
  apiHasError: boolean
  handleAddRecipient: (typeId: string) => void
  isLast?: boolean
  idx?: number
  emailsUpdated: ApiUserVerifiedEmailsObj[] | undefined
  handleNewValue: (newValue: ApiUserVerifiedEmailsObj) => void
  handleEmailsRefetch: () => void
}

export const CredentialFieldVerifyEmail: React.FC<Props> = ({
  index,
  apiHasError,
  handleAddRecipient,
  isLast,
  idx,
  emailsUpdated,
  handleNewValue,
  handleEmailsRefetch,
}) => {
  const { t } = useTranslation()
  const [{ value: field }, { error }, { setValue }] =
    useField<IntegrationFormField>(`credentials.${index}`)
  const { values } = useFormikContext<IntegrationFormValues>()
  const [newValue, setNewValue] = useState<ApiUserVerifiedEmailsObj>()
  const [showModal, toggleShowModal] = useState(false)
  const [code, setCode] = useState('')
  const handleOpenModal = () => toggleShowModal(true)
  const handleCloseModal = () => toggleShowModal(false)
  const [showAddButton, setShowAddButton] = useState(false)
  const [showRemoveButton, setShowRemoveAddButton] = useState(true)
  const [allVerified, setAllVerified] = useState(false)

  const emailValidation =
    field.type === 'validate_email' || field.type === 'validate_email_multi'
  const isMulti = field.type === 'validate_email_multi'

  const { mutate: sendCode, isLoading: sendCodeLoading } = useSendCode()

  const {
    data: verifyCodeResponse,
    mutate: verifyCode,
    isLoading: verifyCodeLoading,
    isError: verifyCodeError,
  } = useVerifyCode()

  const selectOptions: SelectValue[] = emailsUpdated
    ? emailsUpdated.map((option) => ({
        label: option.email,
        value: option.email,
        isVerified: option.status === 'verified',
      }))
    : []

  const currentFieldValue =
    field.type === 'validate_email' && field.value
      ? selectOptions.find((option) => option.value === field.value)
      : Array.isArray(field.value as string[]) && idx !== undefined
      ? selectOptions.find(
          (option) => option.value === (field.value as string[])[idx]
        )
      : undefined

  const handleCodeSubmit = () => {
    if (code && currentFieldValue) {
      verifyCode({
        email: currentFieldValue.value as string,
        code: code as string,
      })
    }
  }

  const handleInputChange = (inputValue: string) => {
    setNewValue({
      email: inputValue,
      status: 'unverified',
    })
  }

  const handleRemoveRecipient = () => {
    if (Array.isArray(field.value)) {
      setValue({
        ...field,
        value:
          field.value.length === 1
            ? ['']
            : field.value.filter((item, index) => idx !== index),
      })
      if (!currentFieldValue?.isVerified) {
        // add here the logic to remove the value from emailsDataState
        handleEmailsRefetch()
      }
    } else {
      setValue({
        ...field,
        value: '',
      })
    }
  }

  const handleKeyDown = () => {
    if (newValue && !emailRegex.test(newValue?.email)) {
      return
    }
    if (newValue && emailsUpdated) {
      if (emailsUpdated.some((item) => item.email === newValue.email)) {
        return
      }
      if (Array.isArray(field.value) && field.value.includes(newValue.email)) {
        return
      }
      handleNewValue(newValue)
      if (Array.isArray(field.value)) {
        setValue({
          ...field,
          value:
            idx !== undefined
              ? (() => {
                  field.value[idx] = newValue?.email as string
                  return [...field.value]
                })()
              : '',
        })
      } else {
        setValue({ ...field, value: newValue.email })
      }
    }
  }

  useEffect(() => {
    if (field.isRequired && typeof idx === 'number') {
      let email: string
      if (field.type === 'validate_email') {
        email = field.value as string
      } else {
        email = (field.value as string[])[idx]
      }

      const emailExists = emailsUpdated?.some((data) => data.email === email)

      if (!emailExists && email !== '') {
        const newValue = {
          email: email,
          status: 'unverified',
          created_at: undefined,
          updated_at: undefined,
        }
        handleNewValue(newValue)
      }
    }
  }, [field.value, idx, emailsUpdated])

  useEffect(() => {
    if (
      (Array.isArray(field.value) &&
        field.value.length === 1 &&
        field.value[0] === '') ||
      field.value === ''
    ) {
      setShowRemoveAddButton(false)
    } else setShowRemoveAddButton(true)
  }, [field])

  const handleVerifyEmail = () => {
    if (currentFieldValue) {
      sendCode({ email: currentFieldValue.value as string })
      handleOpenModal()
    }
  }

  useEffect(() => {
    const hasUnverifiedEmail = emailsUpdated?.some(
      (emailData) =>
        emailData.email === currentFieldValue?.value &&
        emailData.status === 'unverified'
    )
    if (hasUnverifiedEmail) {
      setAllVerified(false)
    } else {
      setAllVerified(true)
    }
  }, [emailsUpdated])

  useEffect(() => {
    if (isLast && currentFieldValue && allVerified) {
      setShowAddButton(true)
    }
  }, [currentFieldValue, isLast])

  useEffect(() => {
    if (verifyCodeResponse === 1) {
      handleCloseModal() // closes out the modal when the email was successfully validated
      handleEmailsRefetch()
    }
  }, [verifyCodeResponse])

  const isRefreshingFromApi = Boolean(
    values.ui?.refresh && values.ui.refresh.includes(field.id)
  )

  return (
    <div style={{ position: 'relative' }}>
      {isRefreshingFromApi && !apiHasError && <Loader $active $dimmer />}
      <InputWrapper isVerified={currentFieldValue?.isVerified}>
        <InputSmartSelect
          placeholder={'Select or type a new email'}
          noIcon
          emailValidation={emailValidation}
          isSearchable={emailValidation}
          isClearable={false}
          initialValues={selectOptions}
          defaultValue={currentFieldValue}
          key={currentFieldValue?.value}
          noOptionsMessageText={t(
            'nbee.bridgeBuilder.inputEmailValidationPlaceholder'
          )}
          onSelect={(selectedValue) => {
            const newValue = selectedValue as SelectValue | null
            if (field.type === 'validate_email') {
              setValue({
                ...field,
                value: newValue?.value || '',
              })
            } else {
              if (newValue?.value === currentFieldValue?.value) {
                return
              }
              if (Array.isArray(field.value)) {
                if (!newValue?.value) {
                  return
                }
                const strings = field.value.map(String)
                if (strings.includes(String(newValue?.value))) {
                  setValue({
                    ...field,
                    value: [...field.value.slice(0, -1), ''],
                  })
                } else {
                  setValue({
                    ...field,
                    value:
                      field.value.length === 0
                        ? [newValue?.value as string]
                        : idx !== undefined
                        ? (() => {
                            field.value[idx] = newValue?.value as string
                            return [...field.value]
                          })()
                        : '',
                  })
                }
              }
            }
          }}
          onBlur={handleKeyDown}
          onInputChange={handleInputChange}
          keyDown={(e) => {
            if (e.key === 'Enter') handleKeyDown()
          }}
          isVerified={currentFieldValue?.isVerified}
        />
        {!currentFieldValue?.isVerified && currentFieldValue?.value && (
          <Button
            onClick={handleVerifyEmail}
            type={'button'}
            $variant={'link-primary'}
          >
            Verify email
          </Button>
        )}
      </InputWrapper>
      <div
        style={
          showAddButton || showRemoveButton
            ? {
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '.2rem',
              }
            : {}
        }
      >
        {isMulti && showAddButton ? (
          <>
            <Button
              type={'button'}
              style={{ padding: '.2rem .1rem', fontSize: '12px' }}
              $variant={'link-primary'}
              onClick={() => {
                handleAddRecipient(field.id)
              }}
            >
              {t('nbee.bridgeBuilder.integrationAddInput')}
            </Button>
          </>
        ) : (
          <div></div>
        )}
        {showRemoveButton && (
          <Button
            type={'button'}
            style={{
              padding: '.2rem .1rem',
              fontSize: '12px',
              color: '#fe2c55',
            }}
            $variant={'link-primary'}
            onClick={handleRemoveRecipient}
          >
            Remove
          </Button>
        )}
      </div>
      {showModal && (
        <Modal
          isResizable={false}
          isOpen={showModal}
          onCloseModal={handleCloseModal}
        >
          <ModalInnerWrapper>
            <h3 style={{ fontWeight: 500 }}>Email Verification</h3>
            <p>
              An email verification code has been sent to {''}
              {newValue && newValue.email}
            </p>
            <div style={{ marginBottom: '1.5em' }}>
              <ReactInputVerificationCode
                autoFocus={true}
                length={6}
                onChange={setCode}
                value={code}
                placeholder={''}
                type={'text'}
              />
            </div>

            {verifyCodeError && (
              <p style={{ color: 'red', marginBottom: '1.5em' }}>
                {"It looks like you've inserted an invalid code."}
              </p>
            )}

            {(verifyCodeLoading || sendCodeLoading) && (
              <Loader $active $dimmer $size={'large'} />
            )}

            <Button
              $variant={'primary'}
              style={{ marginBottom: '1.5em', width: '80%' }}
              onClick={handleCodeSubmit}
            >
              Verify Email
            </Button>

            <Button
              $variant={'link-primary'}
              style={{ marginBottom: '1.5em' }}
              onClick={() =>
                currentFieldValue &&
                sendCode({
                  email: currentFieldValue.value as string,
                })
              }
            >
              {"Didn't receive the code? Resend"}
            </Button>
          </ModalInnerWrapper>
        </Modal>
      )}

      {/* Following block shows a grid with validation error message and refresh icon */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gap: '1rem',
        }}
      ></div>
    </div>
  )
}
