import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { StyledAuthorizationPopup } from './styled'
import { useGetAppById } from '@app/api/getAppById'
import ReactPlayer from 'react-player'
import { Modal } from '@components/Basic/Modal'
import { useTranslation } from 'react-i18next'
import { Loader } from '@components/Basic/Loader'
import { trackEvent } from '@app/dataTracking'
import { ReactComponent as VideoIcon } from '@assets/images/video_tutorial.svg'
import { ReactComponent as DocIcon } from '@assets/images/manual.svg'

const { IconsWrapper, IconRow, InfoSectionWrapper, InfoSectionTitle } =
  StyledAuthorizationPopup

interface Props {
  appId: number
  integrationId?: number
  integrationName?: string
  bridgeId?: number
}

export const InfoSection: React.VFC<Props> = ({
  appId,
  integrationId,
  integrationName,
  bridgeId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingVideoPlayer, setIsLoadingVideoPlayer] = useState(true)

  const { t } = useTranslation()

  const { data: appData } = useGetAppById(appId)

  const videoId = appData?.video
  const docUrl = appData?.docUrl

  return (
    <InfoSectionWrapper>
      <InfoSectionTitle>How to connect the integration</InfoSectionTitle>
      <IconsWrapper>
        {videoId && (
          <IconRow
            data-tooltip-id={'video-tooltip'}
            onClick={() => {
              trackEvent({
                eventName: 'HowToVideoPlayClicked',
                feature: 'NBEE',
                step: 'Integration',
                params: {
                  appId: appId,
                  appName: appData?.name,
                  integrationId: integrationId,
                  integrationName: integrationName,
                  bridgeId: bridgeId,
                },
              })
              setIsModalOpen(true)
            }}
          >
            <VideoIcon />
            <p>Video Tutorial</p>
            <Tooltip
              id={'video-tooltip'}
              place={'top'}
              data-tooltip-content={t(
                'nbee.bridgeBuilder.integrationVideoTooltip'
              )}
            />
          </IconRow>
        )}

        {docUrl && (
          <IconRow
            data-tooltip-id={'doc-tooltip'}
            onClick={() => {
              trackEvent({
                eventName: 'HowToDocuClicked',
                feature: 'NBEE',
                step: 'Integration',
                params: {
                  appId: appId,
                  appName: appData?.name,
                  integrationId: integrationId,
                  integrationName: integrationName,
                },
              })
              window.open(docUrl)
            }}
          >
            <DocIcon />
            <p>Manual</p>
            <Tooltip
              id={'doc-tooltip'}
              place={'top'}
              data-tooltip-content={t(
                'nbee.bridgeBuilder.integrationDocTooltip'
              )}
            />
          </IconRow>
        )}
      </IconsWrapper>
      <Modal
        isOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        isResizable
        hideCloseButton
      >
        {isLoadingVideoPlayer && (
          <Loader $active={isLoadingVideoPlayer} $size={'large'} $dimmer />
        )}

        <div style={{ position: 'relative', paddingTop: '56.25%' }}>
          <ReactPlayer
            width='100%'
            height='100%'
            style={{
              padding: '1rem',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            controls
            volume={1}
            muted
            onReady={() => {
              setIsLoadingVideoPlayer(false)
            }}
            config={{
              wistia: {
                options: { autoPlay: true },
              },
            }}
            url={`https://home.wistia.com/medias/${videoId}`}
          />
        </div>
      </Modal>
    </InfoSectionWrapper>
  )
}
