import React from 'react'
import { InputField } from '@components/Form/InputField'
import { Button } from '@components/Basic/ButtonNbe'
import { Trans, useTranslation } from 'react-i18next'
import {
  HeaderSearchWrapper,
  TopHeader,
  SubHeader,
  InputWrapper,
  SideLogoStyled,
  ClearInputButton,
  TopHeaderMiddle,
} from './styled'
import { AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai'
import { MdOutlineRefresh } from 'react-icons/md'
import { Tooltip } from 'react-tooltip'
import { FieldsProgressBar } from '@features/nbee/FieldsMappingForm/HeaderSearch/FieldsProgressBar'

export interface HeaderSearchProps {
  totalFields: number
  mappedFields: number
  sourceName: string
  sourceLogoUri: string
  destinationName: string
  destinationLogoUri: string
  showAllFields: boolean
  onShowAllFieldsRequest: (showAllFields: boolean) => void
  inputValue: string
  onInputChange: (value: string) => void
  isInTestScreen?: boolean
  showFilterButton: boolean
  onRefetchFieldRequest: () => void
  isReloadingAllBridgeFields: boolean
  isReadonlyModeEnabled?: boolean
  maxFields?: number
}

export type SideLogoProps = {
  logoPosition: 'left' | 'right'
  logoUri?: string
  name?: string
}

const SideLogo: React.FC<SideLogoProps> = ({ logoPosition, logoUri, name }) => {
  return (
    <SideLogoStyled logoPosition={logoPosition}>
      <img src={logoUri} alt={name} title={name} />
      {name && <p>{name}</p>}
    </SideLogoStyled>
  )
}

export const HeaderSearchComponent: React.FC<HeaderSearchProps> = ({
  totalFields,
  mappedFields,
  sourceName,
  sourceLogoUri,
  destinationName,
  destinationLogoUri,
  inputValue,
  onInputChange,
  showAllFields,
  onShowAllFieldsRequest,
  isInTestScreen,
  showFilterButton,
  onRefetchFieldRequest,
  isReloadingAllBridgeFields,
  isReadonlyModeEnabled,
  maxFields,
}) => {
  const { t } = useTranslation()

  return (
    <HeaderSearchWrapper>
      <TopHeader>
        <SideLogo
          logoPosition={'left'}
          name={destinationName}
          logoUri={destinationLogoUri}
        />
        {isInTestScreen || !totalFields ? null : (
          <div style={{ textAlign: 'center' }}>
            <TopHeaderMiddle
              isReloadingAllBridgeFields={isReloadingAllBridgeFields}
            >
              {!isReadonlyModeEnabled ? (
                <>
                  <Tooltip id='topbar-refetch' place='top' variant='dark'>
                    <span>
                      {t('nbee.fieldsMapping.refetchBridgeFieldButton')}
                    </span>
                  </Tooltip>
                  <span
                    data-tooltip-content='topbar-refetch'
                    onClick={() => {
                      onRefetchFieldRequest()
                    }}
                  >
                    <MdOutlineRefresh size={24} />
                  </span>
                </>
              ) : null}
              {maxFields ? (
                <>
                  <Tooltip id='max-fields' place='top' variant='dark'>
                    <span>
                      <Trans
                        ns={'all'}
                        i18nKey={'nbee.fieldsMapping.fieldCounterLimitation'}
                        components={{
                          br: <br />,
                        }}
                        values={{ maxFields: maxFields }}
                      />
                    </span>
                  </Tooltip>
                  <p
                    data-tooltip-id='max-fields'
                    style={{
                      color: mappedFields > maxFields ? '#cc2029' : '#121232',
                      cursor: 'help',
                    }}
                  >
                    {t('nbee.fieldsMapping.totalMaxField', {
                      mappedFields: mappedFields,
                      maxFields: maxFields,
                    })}
                  </p>
                </>
              ) : (
                t('nbee.fieldsMapping.totalMapped', {
                  mappedFields: mappedFields,
                  totalFields: totalFields,
                })
              )}
            </TopHeaderMiddle>
            {maxFields && (
              <div>
                <FieldsProgressBar
                  maxFields={maxFields}
                  mappedFields={mappedFields}
                />
              </div>
            )}
          </div>
        )}
        <SideLogo
          logoPosition={'right'}
          name={sourceName}
          logoUri={sourceLogoUri}
        />
      </TopHeader>
      {!isInTestScreen && totalFields > 0 ? (
        <SubHeader>
          <InputWrapper>
            <InputField
              $fluid
              name={'search-input'}
              type={'text'}
              placeholder={t('nbee.fieldsMapping.searchFields')}
              value={inputValue}
              onChange={({ target: { value } }) => {
                onInputChange(value)
              }}
              slotLeft={
                <AiOutlineSearch size={'1em'} style={{ marginLeft: '.8rem' }} />
              }
              slotRight={
                inputValue ? (
                  <ClearInputButton
                    onClick={() => {
                      onInputChange('')
                    }}
                  >
                    <AiOutlineCloseCircle />
                  </ClearInputButton>
                ) : null
              }
            />
          </InputWrapper>
          {showFilterButton ? (
            <Button
              type={'button'}
              onClick={() => {
                onShowAllFieldsRequest(!showAllFields)
              }}
              $variant={'filter'}
            >
              {showAllFields
                ? t('nbee.fieldsMapping.hideUnmapped')
                : t('nbee.fieldsMapping.showUnmapped')}
            </Button>
          ) : null}
        </SubHeader>
      ) : null}
    </HeaderSearchWrapper>
  )
}
