import React from 'react'
import styled from 'styled-components'

interface ProgressBarProps {
  maxFields: number
  mappedFields: number
}

/* const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-4deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(4deg); }
  100% { transform: rotate(0deg); }
` */

// To move to ProgressBarContainer if we want to animate it
/*
  ${({ percentage }) =>
    percentage === 100 &&
    css`
      animation: 0.2s ${wiggle} 0.5s;
    `}
   */

const ProgressBarContainer = styled.div<{ percentage: number }>`
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 0.5rem;
`

const Fill = styled.div<{ percentage: number }>`
  height: 10px;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) =>
    props.percentage <= 100 ? '#3fb1f2;' : '#cc2029'};
  transition: width 0.5s;
`

export const FieldsProgressBar: React.FC<ProgressBarProps> = ({
  maxFields,
  mappedFields,
}) => {
  const percentage = (mappedFields / maxFields) * 100

  return (
    <ProgressBarContainer percentage={percentage}>
      <Fill percentage={percentage} />
    </ProgressBarContainer>
  )
}
