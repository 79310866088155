import React, { useContext, useEffect, useState } from 'react'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { parseApiError } from '@app/api/utils/error'
import { PanelPopupContext } from '@components/Panel'
import { ApiErrorResponse } from 'BackendApi'
import { useFormikContext } from 'formik'
import { BridgeFormValues, AppConfigurationType } from 'Nbee'

type PopupError = ApiErrorResponse | null

export const usePopupError = ({
  type,
  showLogo,
}: {
  type: AppConfigurationType
  showLogo?: boolean
}) => {
  const panelContext = useContext(PanelPopupContext)
  const { values, setFieldValue } = useFormikContext<BridgeFormValues>()
  const [error, setError] = useState<PopupError>(null)

  const logoUri = values.ui && values.ui[type] && values.ui[type]?.logoUri

  useEffect(() => {
    if (!panelContext.sendPopup) {
      return
    }
    panelContext.sendPopup(undefined)
    setFieldValue(`ui.${type}.hasSettingsApiError`, false)
    setFieldValue(`ui.${type}.hasIntegrationApiError`, false)
    if (error) {
      // showing popup
      const parsedError = parseApiError(error)
      setFieldValue(`ui.${type}.hasSettingsApiError`, true)
      panelContext.sendPopup({
        content: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.6rem',
            }}
          >
            {showLogo && logoUri ? (
              <img src={logoUri} width={'20'} alt={'logo'} />
            ) : null}
            <InputFeedback
              $status={{
                error: parsedError.message,
              }}
            />
          </div>
        ),
        dismissable: true,
        position: 'bottom',
      })

      // if we detect an error related to the integration dropdown we set it a specific error state
      if (parsedError && parsedError.code === 'integration-error') {
        setFieldValue(`ui.${type}.hasIntegrationApiError`, true)
      }
    }
  }, [error])

  const showPopupError = (error: PopupError) => {
    setError(error)
  }

  const clearPopupError = () => {
    setError(null)
  }

  return {
    showPopupError: showPopupError,
    clearPopupError: clearPopupError,
  }
}
