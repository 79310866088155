import {
  EventProperties,
  TrackingEvents,
  TrackingFeature,
  TrackingParams,
  TrackingStep,
} from 'DataTracking'
import store from '@app/store'
import {
  extractEmailFromCognitoUser,
  extractUserIdFromCognitoUser,
} from '@app/dataTracking/utils'
import { CognitoUserInterface } from '@aws-amplify/ui-components'
import { isDebug } from '@app/utils/envUtils'
import { matchPath, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { appRoutes } from '@app/routes'
import { isInIframe } from '@app/utils/isInFrame'

interface ExtendedWindow {
  logTrackCallback: boolean
  enableTrackingDebug: () => void
}

const w = window as unknown as ExtendedWindow
w.logTrackCallback = isDebug
w.enableTrackingDebug = () => {
  w.logTrackCallback = true
}

const isSegmentEnabled = Boolean(process.env.SEGMENT_API_KEY)
const isEmbedded = isInIframe()

export const trackEvent = ({
  eventName,
  feature,
  step,
  params,
}: {
  eventName: TrackingEvents
  feature: TrackingFeature
  step?: TrackingStep
  params?: TrackingParams
}) => {
  if (!isSegmentEnabled) {
    return
  }

  if (!eventName) {
    console.warn('received a tacking event request without a valid event name')
    return
  }
  if (!window.analytics) {
    console.warn('segment script not found')
    return
  }

  // getting data from redux
  const { user } = store.getState()
  const { userId, accountId } = extractUserIdFromCognitoUser(user.auth)
  const { custom, ...eventParams } = params || {}

  const properties: EventProperties = {
    feature: feature,
    step: step,
    userId: userId,
    lbAccountId: accountId,
    embedded: isEmbedded,
    ...eventParams, // some events (eg. signup) might also pass `userId` (eg. signup) in this case redux content will be replaces to what passed in the event as userId
    ...custom,
  }

  // Send track event to segment, with the specified payload
  if (w.logTrackCallback) {
    console.info('sending event: ', eventName, properties)
  }
  window.analytics.track(eventName, properties, undefined, () => {
    if (w.logTrackCallback) {
      console.info(`segment has logged -> ${eventName}`)
    }
  })
}

export const identifyUser = (user?: CognitoUserInterface) => {
  if (!isSegmentEnabled) {
    return
  }

  if (!window.analytics) {
    console.warn('segment script not found')
    return
  }

  const { userId } = extractUserIdFromCognitoUser(user)
  const { email } = extractEmailFromCognitoUser(user)

  window.analytics.identify(
    userId,
    {
      email: email,
    },
    undefined,
    () => {
      if (w.logTrackCallback) {
        console.log(`Identify -> ${userId}`)
      }
    }
  )
}

export const identifyUserByUid = async (userId?: string, email?: string) => {
  if (!isSegmentEnabled) {
    return
  }

  if (!window.analytics) {
    console.warn('segment script not found')
    return
  }

  if (userId) {
    window.analytics.identify(
      userId,
      {
        email: email,
      },
      undefined,
      () => {
        if (w.logTrackCallback) {
          console.log(`Identify by uid -> ${userId}`)
        }
        // This is usefull so we can wait for the identify event to be completed before sending new events.
        // Eg: to be used in signup, so before sending the signup event we can be sure to have identified the user first.
        Promise.resolve()
      }
    )
  }
}

export const forgetUser = () => {
  if (!isSegmentEnabled) {
    return
  }

  if (!window.analytics) {
    console.warn('segment script not found')
    return
  }

  window.analytics.reset()
}

// React hook to be used to connect read-router with page analytics tracking event
export const useTrackPageView = (isUserLoggedIn: boolean) => {
  const location = useLocation()

  useEffect(() => {
    // we don't want to send page views for `/`, `/signin` or `logout`

    const paths = [appRoutes.logout.path, appRoutes.signin.path]

    const isInExcludedPath = paths.find((path) =>
      matchPath({ path: path }, location.pathname)
    )

    const isInRootPath = location.pathname === '/'
    const doNotSendPageView = isInExcludedPath || isInRootPath

    if (!isSegmentEnabled || doNotSendPageView) {
      return
    }

    if (window.analytics && isUserLoggedIn) {
      // we only send page-view when user is authenticated
      window.analytics.page(location.pathname)
    }
  }, [location, isUserLoggedIn])
}
