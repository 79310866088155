import React from 'react'
import { useField } from 'formik'
import { InputSelect, SelectFieldProps } from '@components/Form/InputSelect'

export const FormikInputSelect: React.FC<SelectFieldProps> = ({
  name,
  ...rest
}) => {
  const [field, meta] = useField({
    name: name,
  })

  return (
    <InputSelect
      {...rest}
      {...field}
      $status={{
        ...rest.$status,
        error: (meta.touched && meta.error) || undefined,
      }}
    />
  )
}
