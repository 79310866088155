import { useCallback, useEffect, useRef } from 'react'

export const useClickAway = (onClickAway: () => void) => {
  if (!onClickAway) {
    return null
  }

  const ref = useRef<HTMLDivElement | null>(null)

  const escapeListener = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClickAway()
    }
  }, [])

  const clickListener = useCallback(
    (event: MouseEvent) => {
      if (!ref || !ref.current || ref.current.contains(event.target as Node)) {
        // no ref or if clicked event target is part on current ref
        // this means clicked inside our ref
        return
      }

      // click outside
      onClickAway()
    },
    [ref.current]
  )

  useEffect(() => {
    document.addEventListener('click', clickListener)
    document.addEventListener('keyup', escapeListener)
    return () => {
      document.removeEventListener('click', clickListener)
      document.removeEventListener('keyup', escapeListener)
    }
  }, [])

  return ref
}
