import React from 'react'
import { FieldToggleStatus } from '@features/nbee/EmailReceipt/FieldToggleStatus'
import styled from 'styled-components'
import { useUpdateBridgeToPricing } from '@features/nbee/utils'

const Wrapper = styled.div`
  // pointer-events: none;
  display: flex;
  gap: 1rem;
  cursor: pointer;
`

interface Props {
  requiredLbPlanId?: number
  moduleReceiptId?: string
  bridgeId?: string | number | undefined
}

export const FeatureNotAvailable: React.VFC<Props> = ({
  requiredLbPlanId,
  moduleReceiptId,
  bridgeId,
}) => {
  const updateBridgeToPricing = useUpdateBridgeToPricing(
    bridgeId,
    moduleReceiptId,
    requiredLbPlanId
  )

  return (
    <div>
      <Wrapper onClick={() => updateBridgeToPricing()}>
        <FieldToggleStatus name={'active'} forcedDisabled />
      </Wrapper>
    </div>
  )
}
