import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import ToolSquare from '@components/ToolSquare'
import { Accordion, Segment, Icon as SemanticIcon } from 'semantic-ui-react'
import { BsArrowRight } from 'react-icons/bs'
import styled from 'styled-components'
import { useFetchIntegrationsMetaForBridgeByUrl } from '@app/api/integrationTools'
import AuthContainer, {
  AuthContainerLinks,
} from '@features/auth/components/AuthContainer'
import { PartnerConnectButton } from '@features/auth/bridgeByUrl/PartnerConnectButton'
import { useAppSelector } from '@app/store/hooks'
import { appRoutes } from '@app/routes'
import { Link } from 'react-router-dom'
import { BbuPartnersName } from '@app/enums/bridgeByUrl'
import { getInvalidBbuUrlErrors } from '@features/auth/bridgeByUrl/utils'

const SignInWrapper = styled.div`
  padding-bottom: 1rem;
  @media (min-height: 801px) {
    min-height: 275px;
  }
`
const LogoGroupStyled = styled(Row)`
  max-width: 80%;
  margin: 0 auto !important;
`
const AlreadyLoggedWrapper = styled.div`
  padding: 1rem 0;
  text-align: center;
`

const HeadlineStyled = styled.h2`
  margin-bottom: 1rem !important;
  font-size: 1.2rem;
  font-weight: 600;

  @media (min-width: 801px) {
    font-size: 1.4rem;
  }
`

interface Props {
  partnerName: BbuPartnersName
  formComponent?: React.ReactNode
  links?: AuthContainerLinks
}

export const SignInBridgeByUrl: React.FC<Props> = ({
  partnerName,
  formComponent,
  links,
}) => {
  // Fetch bridge-by-url integration data
  const pathnameParts = window.location.pathname.split('/')
  const sourceName = pathnameParts[3]
  const destinationName = pathnameParts[4]
  const { isLoading, error, data } = useFetchIntegrationsMetaForBridgeByUrl(
    sourceName,
    destinationName
  )

  const canLogin = !isLoading && !error
  const noData = canLogin && !data
  // when partner is app (nbee bbu) we show login form expanded already since there's no oauth
  const loginFormIsInitallyOpen = partnerName === 'app'
  const [showLoginForm, setShowLoginForm] = useState(loginFormIsInitallyOpen)

  const cognitoUser = useAppSelector((state) => state.user.auth)

  const integrationLogos = data ? (
    <SignInWrapper>
      <LogoGroupStyled nogutter={true} align={'center'} className={'my-2'}>
        <Col>
          <ToolSquare
            name={data.source.name}
            logoUri={data.source.logoSmallUri}
          />
        </Col>
        <Col sm={2}>
          <BsArrowRight />
        </Col>
        <Col>
          <ToolSquare
            name={data.destination.name}
            logoUri={data.destination.logoSmallUri}
          />
        </Col>
      </LogoGroupStyled>
      <HeadlineStyled>
        {data.meta.headline ||
          `Use LeadsBridge to connect ${data.source.name} with ${data.destination.name}`}
      </HeadlineStyled>
    </SignInWrapper>
  ) : null

  return (
    <AuthContainer
      loading={isLoading}
      header={integrationLogos}
      slot={
        canLogin && !noData ? (
          <PartnerConnectButton
            partnerName={partnerName}
            integrationsMeta={{
              source: {
                id: data?.source.id || 0,
                name: data?.source?.name || '',
                logo: data?.source?.logoSmallUri || '',
              },
              destination: {
                id: data?.destination.id || 0,
                name: data?.destination?.name || '',
                logo: data?.destination?.logoSmallUri || '',
              },
              // request uri is the full url, including domain
              requestUri: window.location.href,
            }}
          />
        ) : (
          <div>
            <p>{getInvalidBbuUrlErrors(partnerName).message}</p>
            <p>
              <a
                href={getInvalidBbuUrlErrors(partnerName).link}
                target='_blank'
                rel='noreferrer'
              >
                {getInvalidBbuUrlErrors(partnerName).linkText}
              </a>
            </p>
          </div>
        )
      }
      form={
        canLogin && !noData && formComponent ? (
          <Accordion className={'my-2'}>
            {!loginFormIsInitallyOpen ? (
              <Accordion.Title
                className={'text-secondary text-center'}
                active={showLoginForm}
                index={0}
                onClick={() => setShowLoginForm((state) => !state)}
              >
                <SemanticIcon name='dropdown' />
                Already have an account?
              </Accordion.Title>
            ) : null}
            <Accordion.Content active={showLoginForm}>
              <Segment>{formComponent}</Segment>
            </Accordion.Content>
          </Accordion>
        ) : cognitoUser && !error ? (
          <AlreadyLoggedWrapper>
            <div>
              You are logged as <b>{cognitoUser.attributes.email}</b>
            </div>
            <div>
              (
              <Link
                to={appRoutes.logout.makeUrl({ redirectTo: location.pathname })}
              >
                Not you?
              </Link>
              )
            </div>
          </AlreadyLoggedWrapper>
        ) : null
      }
      links={links}
    />
  )
}
