import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

const localStorageKey = 'LB_SIGNUP'
const defaultTrialDays = 14

type SignupParams = {
  cbPlan: string
  couponCode?: string
  trialDays: number
  sourceId?: string
  destinationId?: string
  ref?: string
}

const defaultParams: SignupParams = {
  cbPlan: 'freemium',
  trialDays: defaultTrialDays,
}

export const useHideParamsFromString = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const parsedUrl = queryString.parse(location.search)
  const cbPlan = parsedUrl && (parsedUrl.cbPlan as string)
  const couponCode = parsedUrl && (parsedUrl.cc as string | undefined)
  const trialDays =
    (parsedUrl && (parsedUrl.td as string)) || `${defaultTrialDays}`

  const sourceId = parsedUrl && (parsedUrl.sourceId as string)
  const destinationId = parsedUrl && (parsedUrl.destinationId as string)

  const ref = parsedUrl && (parsedUrl.ref as string)

  if (!Object.keys(parsedUrl).length) {
    // prevent endless redirection loop
    return
  }

  const paramsFromQuery: SignupParams = {
    cbPlan: cbPlan || 'freemium',
    couponCode: couponCode,
    trialDays: parseInt(trialDays, 10),
    sourceId: sourceId,
    destinationId: destinationId,
    ref: ref,
  }

  // storing in base64 encode
  localStorage.setItem(localStorageKey, btoa(JSON.stringify(paramsFromQuery)))

  // remove params from url
  navigate(location.pathname, { replace: true })
}

export const getPersistentSignupParams = () => {
  try {
    // decode from base64
    const savedString = atob(localStorage.getItem(localStorageKey) || '')
    return JSON.parse(savedString) as SignupParams
  } catch (e) {
    return defaultParams
  }
}

export const deletePersistendSignupParms = () => {
  localStorage.removeItem(localStorageKey)
}
