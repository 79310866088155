import React, { useEffect, useState } from 'react'
import { Modal } from '@components/Basic/Modal'
import { ApiAppSetting } from 'BackendApi'
import { Button } from '@components/Basic/ButtonNbe'
import { useFormikContext } from 'formik'
import { BridgeFormValues, AppConfigurationType } from 'Nbee'
import { LabelWithDocTooltip } from '@components/Form/LabelWithDocTooltip'
import { InputToggle } from '@components/Form/InputToggle'
import { InputField } from '@components/Form/InputField'
import { useAddDropdownOption } from '@app/api/postAddDropdownOption'
import { Message } from '@components/Basic/Message'
import { parseApiError } from '@app/api/utils/error'
import { ModalAddOptionCard, ModalAddOptionErrorMessage } from './styled'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { useTranslation } from 'react-i18next'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'

interface Props {
  isOpen: boolean
  onCloseModal: () => void
  onSuccessCreation: (newOptionId: number | string) => void
  fieldSchema: ApiAppSetting
  type: AppConfigurationType
}

export const SmartSelectAddOptionApiModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  fieldSchema,
  type,
  onSuccessCreation,
}) => {
  const { t } = useTranslation()
  const [validationMessage, setValidationMessage] = useState('')
  const [settingsValues, setSettingsValues] = useState<
    Record<string, string | boolean>
  >({})
  const { values } = useFormikContext<BridgeFormValues>()
  const addOptionSettings = fieldSchema.addOption?.apiSettings || []

  const {
    mutate: addOptionMutation,
    data: addOptionResponse,
    isLoading: isAddingOption,
    error: apiError,
  } = useAddDropdownOption()

  // init empty state for inputs
  useEffect(() => {
    if (addOptionSettings) {
      const intialSettingsValuesState = Object.fromEntries(
        addOptionSettings.map(({ id, type }) => [
          id,
          type === 'toggle' ? false : '',
        ])
      )

      setSettingsValues(intialSettingsValuesState)
    }
  }, [addOptionSettings])

  // on success
  useEffect(() => {
    if (addOptionResponse && addOptionResponse.data?.instanceId) {
      onSuccessCreation(addOptionResponse.data.instanceId)
    }
  }, [addOptionResponse])

  const addOptionSchema = fieldSchema.addOption!
  const apiScope = addOptionSchema.apiScope || ''
  const apiSettings = Object.keys(settingsValues).map((o) => ({
    key: o,
    value: settingsValues[o],
  }))

  // we only check if all text inputs are filled
  const isFormValid = apiSettings.every(
    (a) => a.value === false || `${a.value}`.trim() !== ''
  )

  const addOptionSubmit = () => {
    setValidationMessage('')

    if (!isFormValid) {
      setValidationMessage('All fields are required')
      return
    }

    addOptionMutation({
      integrationId: values[type].integrationId!,
      type: type,
      settings: values[type].settings || [],
      apiScope: apiScope,
      apiSettings: apiSettings,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onCloseModal={() => {
        // we don't want the user closes the modal while posting the new data
        if (!isAddingOption) {
          onCloseModal()
        }
      }}
    >
      <ModalAddOptionCard>
        <h1>Create new {fieldSchema.label}</h1>

        {addOptionSettings.map((field) => (
          <div key={field.id}>
            {field.type === 'toggle' ? (
              <InputToggle
                $isChecked={!!settingsValues[field.id]}
                onChange={() => {
                  setSettingsValues({
                    ...settingsValues,
                    [field.id]: !settingsValues[field.id],
                  })
                }}
                $variant={'primary'}
                $labelRight={field.label}
              />
            ) : field.type === 'dropdown' ? (
              <>
                <LabelWithDocTooltip label={field.label} isRequired />
                <InputSmartSelect
                  initialValues={
                    field.data
                      ? field.data.map(({ text, ...rest }) => ({
                          label: text,
                          value: text,
                          ...rest,
                        }))
                      : []
                  }
                  onSelect={(value) => {
                    const singleValue = value as SelectValue
                    if (singleValue) {
                      setSettingsValues({
                        ...settingsValues,
                        [field.id]: singleValue.id,
                      })
                    }
                  }}
                />
              </>
            ) : (
              <>
                <LabelWithDocTooltip label={field.label} isRequired />
                <InputField
                  name={field.id}
                  type={'text'}
                  value={settingsValues[field.id] as string}
                  required
                  placeholder={'Type a name'}
                  onChange={({ currentTarget: { value } }) => {
                    setSettingsValues({
                      ...settingsValues,
                      [field.id]: value,
                    })
                  }}
                />
              </>
            )}
          </div>
        ))}

        {apiError ? (
          <Message $status={'error'}>
            <ModalAddOptionErrorMessage
              dangerouslySetInnerHTML={{
                __html: parseApiError(apiError).message,
              }}
            />
          </Message>
        ) : null}

        {validationMessage ? (
          <div>
            <InputFeedback $status={{ error: validationMessage }} />
          </div>
        ) : null}

        <div style={{ paddingTop: '1rem', textAlign: 'right' }}>
          <Button
            type={'button'}
            disabled={isAddingOption || !isFormValid}
            $loading={isAddingOption}
            $variant={'primary'}
            onClick={addOptionSubmit}
          >
            {t('nbee.bridgeBuilder.addNewGeneric')}
          </Button>
        </div>
      </ModalAddOptionCard>
    </Modal>
  )
}
