import React, { useEffect, useRef, useState } from 'react'
import { Loader } from '@components/Basic/Loader'
import { isDebug } from '@app/utils/envUtils'

export type ConnectedPopupInitialData = Record<string, unknown>

type PostMessageData = {
  isLbMessage: true
  [key: string]: unknown
}
// This component is just an example to be mounte in a specific path
// connected-popup-destination-url-example
// to showcase how to use a connected popup window
export const DestinationContentSample: React.FC = () => {
  const [initialData, setInitialData] = useState<ConnectedPopupInitialData>()
  const [initialDataError, setInitialDataError] = useState<string | null>()
  const initialDataTimoutId = useRef<number | null>(null)
  const initialDataRef = useRef<ConnectedPopupInitialData>()

  const messageReceiverFn = function (event: MessageEvent) {
    // it's should always better to check if origin matches to avoid security issues
    // but since this is an embeddable experience we can't do it :(
    // if (event.origin !== window.location.origin) {
    //   setInitialDataError('skipping message received from uknown parent')
    //   return
    // }

    // checking if it's our message, since react (maybe react-dev-tools) posts its own messages
    // and also we can improve this with by checking a sort of nonce key
    if (event.data.isLbMessage) {
      setInitialData(event.data)
      initialDataRef.current = event.data
    }
  }

  useEffect(() => {
    // we are expecting to receive data as postMessage from parent window
    window.addEventListener('message', messageReceiverFn)
    return () => window.removeEventListener('message', messageReceiverFn)
  }, [])

  useEffect(() => {
    // in case we don't receive initialData from parent window within a given time
    initialDataTimoutId.current = window.setTimeout(() => {
      if (!initialData && !initialDataRef.current) {
        setInitialDataError(
          'No data received from LeadsBridge App. Please close this popup window and try to authorize again.'
        )
      }
    }, 3000)

    return () => {
      if (initialDataTimoutId.current !== null) {
        window.clearTimeout(initialDataTimoutId.current)
      }
    }
  }, [])

  const sendDataToParentHandler = () => {
    const parentWindow = window.opener
    if (!parentWindow) {
      return
    }

    const message: PostMessageData = {
      isLbMessage: true,
      payload: 'something with any shape',
    }
    parentWindow.postMessage(message, '*')
  }

  if (isDebug) {
    // we keep this just for internal showcase
    return null
  }

  return (
    <div>
      {initialDataError ? (
        <div>
          <div>{initialDataError}</div>
          <button
            onClick={() => {
              window.close()
            }}
          >
            Close
          </button>
        </div>
      ) : initialData ? (
        <div>
          <h2>Data received via post message from parent</h2>
          <pre>{JSON.stringify(initialData)}</pre>
          <button onClick={sendDataToParentHandler}>Send some data back</button>
        </div>
      ) : (
        <Loader $active $dimmer $size={'large'} />
      )}
    </div>
  )
}
