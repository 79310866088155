import React from 'react'
import {
  ButtonWrapperStyled,
  FormStyled,
} from '@components/FastAppSurvey/styled'
import { Button } from '@components/Basic/Button'
import { useFastAppSurveyIndustries } from '@app/api/fastAppSurveyIndustries'
import { useStoreSurveyData } from '@app/api/storeFastAppSurvey'
import { getApiErrorMessage } from '@app/api/config'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { useAppDispatch } from '@app/store/hooks'
import { Formik, Form as FormikForm } from 'formik'
import { FormikInputField } from '@components/Formik/FormikInputField'
import { FormikInputSelect } from '@components/Formik/FormikInputSelect'
import { FormikInputCheckbox } from '@components/Formik/FormikInputCheckbox'
import {
  initialFormValues,
  validationSchema,
  FormValues,
} from '@components/FastAppSurvey/formikUtils'
import { Auth } from 'aws-amplify'
import { parseAuthUser } from '@app/features/auth/functions/parseAuthUser'
import { CognitoUserInterface } from '@aws-amplify/ui-components'
import { setCurrentUserAction } from '@app/store/actions/UserActions'
import AuthService from '@app/services/AuthService'

export interface FormProps {
  onSubmitSuccess: () => void
}

export const Form: React.FC<FormProps> = ({ onSubmitSuccess }) => {
  const storeSurveyData = useStoreSurveyData()
  const getIndustries = useFastAppSurveyIndustries()
  const dispatch = useAppDispatch()

  const handleSubmit = ({
    email,
    phone,
    industry,
    privacyConsent,
  }: FormValues) => {
    // Post new data to api
    storeSurveyData.mutate(
      {
        email: email,
        phone: phone,
        industry: industry,
        privacyConsent: privacyConsent,
      },
      {
        onSuccess: async () => {
          console.log('getting fresh user data since email is changed')
          // We need to call Auth.currentAuthenticatedUser with bypassCache as true. In this way we can update
          // our cognito user (which is by default stored/cached as jwt in localStorage by Cognito).
          // In this way we are sure user will not see its old (randomly generated) email address, but the new one.
          const cognitoResponse: CognitoUserInterface =
            await Auth.currentAuthenticatedUser({
              bypassCache: true,
            })
          // storing fresh data to Redux store, so it will be available globally
          const cognitoUser = parseAuthUser(cognitoResponse)
          dispatch(setCurrentUserAction(cognitoUser))

          // as final step, now that the user is properly logged-in with is correct email address,
          // we can store session cooke so it will be recognized in v1
          await AuthService.onLoginAsync(false)

          console.log('proceed with bbu...')
          onSubmitSuccess()
        },
        onError: (error) => {
          dispatch(
            sendToast({
              title: 'Error',
              message: getApiErrorMessage({ error: error as Error }),
              color: 'negative',
            })
          )
        },
      }
    )
  }

  return (
    <FormStyled>
      <p>Please tell us more about yourself</p>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {() => {
          return (
            <FormikForm>
              <FormikInputField
                name='email'
                type='email'
                label='Company Email'
                $fluid
                $hasMargin
              />
              <FormikInputField name='phone' type='tel' label='Phone' $fluid />
              <FormikInputSelect
                $options={getIndustries.data || []}
                $loading={getIndustries.isLoading}
                disabled={Boolean(getIndustries.error)}
                $optionsLabel={
                  getIndustries.error
                    ? 'Unable to retrieve industries'
                    : 'Please select industry'
                }
                name={'industry'}
                $label={'Industry'}
                $hasMargin
              />
              <FormikInputCheckbox name='privacyConsent'>
                I agree with LeadsBridge&nbsp;
                <a
                  href='https://leadsbridge.com/privacy/'
                  target='_blank'
                  rel='noreferrer'
                >
                  privacy policy
                </a>
              </FormikInputCheckbox>
              <ButtonWrapperStyled>
                <Button
                  $loading={
                    storeSurveyData.isLoading || storeSurveyData.isSuccess
                  }
                  type={'submit'}
                  $fluid
                  $variant={'primary'}
                >
                  Get Started
                </Button>
              </ButtonWrapperStyled>
            </FormikForm>
          )
        }}
      </Formik>
    </FormStyled>
  )
}
