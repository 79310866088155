import React, { useLayoutEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { SmartSelectTooltipInfoMessage } from '@components/Form/InputSmartSelect/styled'
import { BsInfoCircle } from 'react-icons/bs'
import { SelectValue } from '@components/Form/InputSmartSelect/index'

interface Props {
  id: string
  tooltipMessage?: string
  label: string
}

export const TooltipComponent: React.VFC<Props> = ({
  id,
  tooltipMessage,
  label,
}) => {
  return (
    <Tooltip
      id={id}
      place={'right'}
      data-tooltip-content={() =>
        tooltipMessage && label ? (
          <>
            <label>{label}</label>
            <SmartSelectTooltipInfoMessage>
              <BsInfoCircle /> <span>{tooltipMessage}</span>
            </SmartSelectTooltipInfoMessage>
          </>
        ) : tooltipMessage ? (
          <SmartSelectTooltipInfoMessage>
            <BsInfoCircle /> <span>{tooltipMessage}</span>
          </SmartSelectTooltipInfoMessage>
        ) : (
          <label>{label}</label>
        )
      }
      className={'react-select-tooltip'}
    />
  )
}

// This hook is used to check if selected label is too long. It only work for single SelectValue.
// It exposes the outer element ref and the inner element ref, so we can check if width exceed.
export const useIsLabelTooLong = ({
  defaultValue,
}: {
  defaultValue: SelectValue
}) => {
  const wrapperEl = useRef<HTMLDivElement | null>(null)
  const selectedSingleValueEl = useRef<HTMLDivElement | null>(null)
  const [outerWidth, setOuterWidth] = useState<number>()
  const [singleValueWidth, setSingleValueWidth] = useState<number>()

  // To get and calculate wrapper width minus icons containers if present
  useLayoutEffect(() => {
    // To check if icon/logo or controllers are present and return default value
    let extraElementsWidth = Array.isArray(defaultValue)
      ? 0
      : defaultValue?.icon
      ? 65
      : 0

    if (wrapperEl.current) {
      // in most of the cases the select has indicators on the right,
      // we added a custom `.indicators-container` so we can retrieve the width do detract
      const indicatorsContainerWidth =
        wrapperEl.current?.querySelector('.indicators-container')
          ?.clientWidth || 0

      extraElementsWidth += indicatorsContainerWidth
      setOuterWidth(wrapperEl.current?.clientWidth - extraElementsWidth)
    }
  }, [wrapperEl])

  // to get single value input width
  useLayoutEffect(() => {
    if (selectedSingleValueEl.current) {
      setSingleValueWidth(selectedSingleValueEl.current?.offsetWidth)
    }
  }, [selectedSingleValueEl])

  const isLabelTooLong =
    defaultValue &&
    !Array.isArray(defaultValue) &&
    singleValueWidth &&
    outerWidth
      ? singleValueWidth > outerWidth
      : false

  return {
    selectedSingleValueEl: selectedSingleValueEl,
    wrapperEl: wrapperEl,
    isLabelTooLong: isLabelTooLong,
  }
}
