import React, { useEffect, useState } from 'react'
import { InputSmartSelect } from '@components/Form/InputSmartSelect'
import {
  AppConfigurationType,
  AppIntegrationSetting,
  BridgeFormValues,
} from 'Nbee'
import { useFormikContext } from 'formik'
import { ApiAppSetting } from 'BackendApi'
import {
  getSettingFieldError,
  handleCreateNewFacebookForm,
  transformSettingDataInSelectOptions,
} from './utils'
import { useTranslation } from 'react-i18next'
import { LabelWithDocTooltip } from '@components/Form/LabelWithDocTooltip'
import { TinyTextualButton } from '@components/Basic/TinyTextualButton'
import { BiRefresh } from 'react-icons/bi'
import { Loader } from '@components/Basic/Loader'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { useTriggerSettingsUpdate } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/useTriggerSettingsUpdate'
import { SmartSelectAddOptionApiModal } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/SmartSelectAddOptionApiModal'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'

interface Props {
  isLoading: boolean
  fieldSchema: ApiAppSetting
  type: AppConfigurationType
  index: number
  apiHasError: boolean
}

export const SettingFieldSmartSelect: React.FC<Props> = ({
  isLoading,
  type,
  fieldSchema,
  index,
  apiHasError,
}) => {
  const [addOptionApiModalOpen, setAddOptionApiModalOpen] = useState(false)
  // some formik helpers
  const {
    values: formValues,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    validateForm,
  } = useFormikContext<BridgeFormValues>()

  const { t } = useTranslation()
  const { refetchSettings } = useTriggerSettingsUpdate(type)

  const settingFieldName = `${type}.settings`
  const currentSettingsValues = formValues[type].settings || []

  const fieldId = fieldSchema.id

  const currentFieldValue = currentSettingsValues.find((v) => v.key === fieldId)
  const initialValues = transformSettingDataInSelectOptions(fieldSchema.data)

  // if we load this component `currentFieldValue.value` can only be string or string[]
  // so it's safe to case.
  const currentFieldValueCasted = currentFieldValue
    ? (currentFieldValue.value as string | string[])
    : undefined

  // getting default value from the list
  // in case is tags (multi-select) we need to filter within an array
  // if is a single value then it's ok to return a single value from the options lsit
  const defaultValue = currentFieldValueCasted
    ? Array.isArray(currentFieldValueCasted)
      ? initialValues.filter((option) =>
          currentFieldValueCasted.includes(`${option.value}`)
        )
      : initialValues.find((option) => option.value === currentFieldValueCasted)
    : undefined

  const { isTouched, fieldStatus } = getSettingFieldError({
    type: type,
    touched: touched,
    errors: errors,
    index: index,
  })

  const updateFormSettings = (newValue: AppIntegrationSetting) => {
    // old logic with update settings in bulk
    // const newSettings = currentFieldValue
    //   ? currentSettingsValues.map((setting) =>
    //       setting.key === fieldId ? newValue : setting
    //     )
    //   : [...currentSettingsValues, newValue]
    // setFieldValue(settingFieldName, newSettings)

    // updating single setting to avoid overrides when fetching in bg
    setFieldValue(`${settingFieldName}.${index}`, newValue)

    // we trigger the retrival of new setting only if is a setting with a child
    if (fieldSchema.hasChild) {
      refetchSettings()
    }
  }

  const handleFieldRefreshRequest = () => {
    trackEvent({
      eventName: 'DropdownRefreshClicked',
      feature: 'NBEE',
      step: 'Apps',
      params: {
        ...makeNbeeTrackingParams(formValues, type),
        custom: {
          fieldId: fieldId,
        },
      },
    })

    // we prepare the request to get fresh content (not cached) from api for current field id
    setFieldValue(`ui.${type}.refresh`, [fieldSchema.id])
    // we send the api request
    refetchSettings()
  }

  // if value is not present enymore in the list we need to remove it from formik state
  // example we had a dropdown child that returns different options depending on parent choice
  useEffect(() => {
    const availableOptionKeys = initialValues.map((o) => o.value)
    const currentValue = currentFieldValue?.value as string
    if (currentValue && !availableOptionKeys.includes(currentValue)) {
      updateFormSettings({
        key: fieldId,
        value: '',
      })
    }
  }, [initialValues])

  // When the field is required and it has only one option available,
  // it pre-select that option and show the field in a read-only mode (disabled).
  const fieldHasOnlyOneOption =
    fieldSchema.data && fieldSchema.data[0] && fieldSchema.data.length === 1
  const isRequiredFieldDisabled =
    fieldSchema.required &&
    !fieldSchema.addOption &&
    fieldHasOnlyOneOption &&
    !!currentFieldValue?.value // we also check if current value is set, since during re-fething we might get different results and end-up with a disabled select with no values

  const isRequiredFieldClearable = fieldSchema && !fieldSchema.required
  useEffect(() => {
    if (!fieldSchema.required) {
      return
    }

    // if we have a value there's nothing to set
    if (currentFieldValue && currentFieldValue.value) {
      return
    }

    if (fieldSchema && fieldHasOnlyOneOption) {
      const singleDefaultValue = fieldSchema.data![0].id
      updateFormSettings({ key: fieldId, value: singleDefaultValue })

      setTimeout(() => {
        // we need to re-validate the form since pre-selected values might not be registered
        validateForm()
      }, 200)

      // we force a new update if this field hasChild, but we need to wait a bit to avoid endless loop
      if (fieldSchema.hasChild) {
        setTimeout(() => {
          refetchSettings()
        }, 500)
      }
    }
  }, [fieldSchema])

  const isRefreshingFromApi = Boolean(
    formValues.ui && formValues.ui[type]?.refresh?.includes(fieldSchema.id)
  )

  // we generate an hashed key from the key/value object contained in our defaultValue,
  // se we can keep always the component updated with the new default value returned from the API
  // the concatenation of `unescape(encodeURIComponent(..))` is required because btoa can't decode not Latin chars
  const renderKey =
    defaultValue &&
    window.btoa(unescape(encodeURIComponent(JSON.stringify(defaultValue))))

  return (
    <div style={{ position: 'relative' }}>
      {/*  we show the loader when is hard refreshing from api, but if we received an error we can't show the loading status or user will be stuck  */}
      {isRefreshingFromApi && !apiHasError && <Loader $active $dimmer />}
      <LabelWithDocTooltip
        label={fieldSchema.label}
        isRequired={fieldSchema.required}
        tooltip={fieldSchema.tooltip}
        docUrl={fieldSchema.docUrl}
      />
      <InputSmartSelect
        isLoading={isLoading}
        isDisabled={isRequiredFieldDisabled || formValues.ui?.isBridgeEnabled}
        tooltipMessage={
          isRequiredFieldDisabled && !isLoading
            ? t('common.selectDisabledWithSingleOption')
            : undefined
        }
        isClearable={isRequiredFieldClearable}
        initialValues={initialValues}
        defaultValue={defaultValue}
        key={renderKey}
        onSelect={(selectedValue) => {
          if (!selectedValue) {
            updateFormSettings({
              key: fieldId,
              value: '',
            })
            return
          }

          const newValue: AppIntegrationSetting = {
            key: fieldId,
            value: Array.isArray(selectedValue)
              ? selectedValue.map((v) => v.value.toString())
              : selectedValue.value || '',
          }

          trackEvent({
            eventName: 'IntegrationSettingSelected',
            step: 'Apps',
            feature: 'NBEE',
            params: {
              ...makeNbeeTrackingParams(formValues, type),
              custom: {
                fieldId: newValue.key,
                value: Array.isArray(newValue.value)
                  ? newValue.value.join(', ')
                  : newValue.value,
              },
            },
          })

          // if `newValue.value` is an empty array we need to remove it from formik state
          const newValueIsEmptyArray =
            Array.isArray(newValue.value) && newValue.value.length === 0
          if (newValueIsEmptyArray) {
            const newSettings = currentSettingsValues.filter(
              (setting) => setting.key !== fieldId
            )
            setFieldValue(settingFieldName, newSettings)
            return
          }
          updateFormSettings(newValue)

          // any form has always "999" as id
          if (newValue.value === '999') {
            trackEvent({
              eventName: 'AnyFormSelected',
              feature: 'NBEE',
              step: 'Apps',
              params: {
                ...makeNbeeTrackingParams(formValues, type),
              },
            })
          }
        }}
        addNewOptionButton={
          fieldSchema.addOption?.type === 'fb_forms'
            ? {
                label: t('nbee.bridgeBuilder.addNewForm'),
                onClick: () => {
                  trackEvent({
                    eventName: 'CreateFormClicked',
                    feature: 'NBEE',
                    step: 'Apps',
                    params: makeNbeeTrackingParams(formValues, type),
                  })
                  handleCreateNewFacebookForm(
                    currentSettingsValues,
                    (payload) => {
                      console.log('FB Payload', payload)
                      if (payload) {
                        updateFormSettings({
                          key: fieldId,
                          value: payload.formID,
                        })
                        handleFieldRefreshRequest()
                      }
                    }
                  )
                },
              }
            : fieldSchema.addOption?.redirectUri
            ? {
                label: t('nbee.bridgeBuilder.viewDocumentation'),
                onClick: () => {
                  window
                    .open(fieldSchema.addOption?.redirectUri, '_blank')
                    ?.focus()
                },
              }
            : fieldSchema.addOption?.type === 'api'
            ? {
                label: t('nbee.bridgeBuilder.addNewGeneric'),
                onClick: () => {
                  setAddOptionApiModalOpen(true)
                },
              }
            : undefined
        }
        onBlur={() => {
          setFieldTouched(`${settingFieldName}.${index}`, true)
        }}
      />

      {/* Modal add new option for type API */}
      {addOptionApiModalOpen && (
        <SmartSelectAddOptionApiModal
          isOpen={addOptionApiModalOpen}
          type={type}
          fieldSchema={fieldSchema}
          onCloseModal={() => {
            setAddOptionApiModalOpen(false)
          }}
          onSuccessCreation={(newOptionId) => {
            console.log('newOptionId', newOptionId)
            handleFieldRefreshRequest()
            updateFormSettings({
              key: fieldId,
              value: newOptionId,
            })
            setAddOptionApiModalOpen(false)
          }}
        />
      )}

      {/* Following block shows a grid with validation error message and refresh icon */}
      {!formValues.ui?.isBridgeEnabled ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: '1rem',
          }}
        >
          {isTouched && fieldStatus ? (
            <InputFeedback $status={fieldStatus} />
          ) : (
            <div />
          )}
          {fieldSchema.refreshable ? (
            <div style={{ textAlign: 'right' }}>
              <TinyTextualButton
                onClick={handleFieldRefreshRequest}
                disabled={isLoading}
              >
                <BiRefresh /> {t('nbee.bridgeBuilder.refreshDropdownListCta')}
              </TinyTextualButton>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
