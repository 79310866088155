import styled, { css } from 'styled-components'
import { InputToggleProps } from './index'

const labelWidth = ({ $size }: InputToggleProps) => {
  switch ($size) {
    case 'large':
      return '4.375rem'
    default:
      return '3.8rem'
  }
}

const labelHeight = ({ $size }: InputToggleProps) => {
  switch ($size) {
    case 'large':
      return '1.875rem'
    default:
      return '1.7rem'
  }
}

const sliderWidthHeight = ({ $size }: InputToggleProps) => {
  switch ($size) {
    case 'large':
      return '1.625rem'
    default:
      return '1.5rem'
  }
}

const transitionSize = ({ $size }: InputToggleProps) => {
  switch ($size) {
    case 'large':
      return '37px'
    default:
      return '30.5px'
  }
}

const labelStyle = ({ $variant }: InputToggleProps) => {
  switch ($variant) {
    case 'dark':
      return css`
        color: #273649;
        font-size: 1.125rem;
      `
    case 'primary':
      return css`
        color: #696f8c;
        font-size: 0.875rem;
      `
  }
}

const bgColorChecked = ({ $variant }: Pick<InputToggleProps, '$variant'>) => {
  switch ($variant) {
    case 'dark':
      return '#273649'
    case 'primary':
      return '#fd9645'
  }
}

const bgColorNotChecked = ({
  $variant,
}: Pick<InputToggleProps, '$variant'>) => {
  switch ($variant) {
    case 'dark':
      return '#d9dee1'
    case 'primary':
      return '#919EAB'
  }
}

export const WrapperStyled = styled.div<InputToggleProps>`
  display: flex;
  align-items: center;

  label {
    ${labelStyle}
    ${({ $isChecked, $variant }) => {
      if ($isChecked && $variant === 'dark') {
        return css`
          &:first-child {
            opacity: 0.7;
          }
          &:last-child {
            font-weight: 600;
            letter-spacing: -0.3px;
          }
        `
      } else if (!$isChecked && $variant === 'dark') {
        return css`
          &:last-child {
            opacity: 0.7;
          }
          &:first-child {
            font-weight: 600;
            letter-spacing: -0.3px;
          }
        `
      }
    }}
`

export const LabelStyled = styled.label<InputToggleProps>`
  position: relative;
  display: inline-block;
  min-width: ${labelWidth};
  width: ${labelWidth};
  height: ${labelHeight};

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          pointer-events: none;
        `
      : null}

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const SpanStyled = styled.span<InputToggleProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 18.5px;

  ${({ $isChecked }) =>
    $isChecked
      ? css`
          background-color: ${bgColorChecked};
        `
      : css`
          background-color: ${bgColorNotChecked};
        `};

  

  &:before {
    position: absolute;
    content: '';
    width: ${sliderWidthHeight};
    height: ${sliderWidthHeight};
    left: 4px;
    bottom: 1.5px;
    transition: 0.4s;
    background-color: #fff;
    border-radius: 18.5px;
    box-shadow: 1px 1px 4px 0 rgba(67, 62, 62, 0.27);

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        transform: translateX(${transitionSize});
      `};
    
`
