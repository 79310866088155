import React from 'react'
import { InputField } from '@components/Form/InputField'
import { useField } from 'formik'
import { Textarea } from '@components/Form/Textarea'
import { IntegrationFormField } from 'Nbee'
import { InputFeedbackStatus } from '@components/Basic/InputFeedback'

interface Props {
  index: number
  isLoading?: boolean
}

export const CredentialFieldInput: React.FC<Props> = ({ index, isLoading }) => {
  const [{ value: field, onBlur }, { touched, error }, { setValue }] =
    useField<IntegrationFormField>(`credentials.${index}`)

  const [_, __, { setValue: setTriggerSettingsUpdate }] = useField<number>(
    `ui.triggerSettingsUpdate`
  )

  const fieldType = field.type
  // safe to cast since if we load this componnet value must be a string
  const currentValue = field.value as string

  // error could a string or an object with value: string
  const parsedError = error ? (error as any).value || error : null
  const status: InputFeedbackStatus | undefined =
    touched && error
      ? {
          error: parsedError,
        }
      : undefined

  const handOnChange = (value: string) => {
    setValue({
      ...field,
      value: value,
    })
  }

  const handleFreshContentRequest = () => {
    if (field.hasChild) {
      setTriggerSettingsUpdate(new Date().getTime())
    }
  }

  return (
    <div>
      {fieldType === 'textarea' ? (
        <Textarea
          id={field.id}
          name={field.id}
          value={currentValue}
          onChange={({ target: { value } }) => {
            handOnChange(value)
          }}
          onBlur={(e) => {
            handleFreshContentRequest()
            onBlur(e)
          }}
          $status={status}
          disabled={field.hasChild && isLoading}
        />
      ) : (
        <InputField
          type={
            fieldType === 'password'
              ? 'password'
              : fieldType === 'email'
              ? 'email'
              : fieldType === 'url'
              ? 'url'
              : fieldType === 'number'
              ? 'number'
              : 'text'
          }
          autoComplete={fieldType === 'password' ? 'new-password' : 'off'}
          id={field.id}
          name={field.id}
          value={currentValue}
          onChange={({ target: { value } }) => {
            handOnChange(value)
          }}
          onBlur={(e) => {
            handleFreshContentRequest()
            onBlur(e)
          }}
          $status={status}
          disabled={field.hasChild && isLoading}
        />
      )}
    </div>
  )
}
