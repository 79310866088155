import React, { ReactNode } from 'react'
import { DropdownItemStyled } from './styled'

interface DropdownMenuItemProps {
  onClick?: () => void
  className?: string
  isActive?: boolean
  isDisabled?: boolean
  children?: ReactNode
}

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  children,
  onClick,
  className,
  isActive,
  isDisabled,
}) => {
  return (
    <DropdownItemStyled
      isActive={isActive}
      onClick={onClick}
      isDisabled={isDisabled}
      className={className}
    >
      {children}
    </DropdownItemStyled>
  )
}
