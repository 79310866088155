import React from 'react'
import { Outlet } from 'react-router-dom'

export const AuthRouter: React.FC = () => {
  return (
    <div className={'auth-slot-container'}>
      <div className={'login-slot'}>
        <Outlet />
      </div>
    </div>
  )
}
