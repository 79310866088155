import React from 'react'
import { Button } from '@components/Basic/Button'
import { Tooltip } from 'react-tooltip'
import { Link, matchPath } from 'react-router-dom'
import { appRoutes, bridgeByUrlPartnersPaths } from '@app/routes'
import { BbuPartnersName } from '@app/enums/bridgeByUrl'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AuthScope } from '@components/ButtonProvider'

const Message = styled.div`
  color: ${({ theme }) => theme.color.error};
  font-size: 0.9rem;
`

interface Props {
  scope: AuthScope
}

export const ButtonErrorUi: React.FC<Props> = ({ scope }) => {
  const { t } = useTranslation()

  const bbuMatchUrl = bridgeByUrlPartnersPaths
    .map((path) => matchPath({ path: path }, location.pathname))
    .find((match) => match !== null && match !== undefined)

  const partnerName = bbuMatchUrl?.params?.partnerName

  const isFbBbu =
    partnerName &&
    [
      BbuPartnersName.facebook,
      BbuPartnersName.facebookConversionLeads,
    ].includes(partnerName as BbuPartnersName)

  const signupBbuRoute = bbuMatchUrl?.params
    ? appRoutes.signupBbu.makeUrl({
        source: bbuMatchUrl.params.source || '',
        destination: bbuMatchUrl.params.destination || '',
      })
    : undefined

  console.log(signupBbuRoute)
  // In case of error and we are in signup page, we just hide everything without showing any message.
  // This because we use the signup bbu page as fallback in case user won't disable the ad-blocker
  // and in the bbu signup page we don't want to be redundant
  if (scope === 'SignUp') {
    return null
  }

  return (
    <>
      {/* In case of Facebook BBU we give the user the possibility to navigate to signup bbu and register a new account with email (no social)  */}
      {isFbBbu && signupBbuRoute ? (
        <div>
          <Button $fluid $variant={'facebook'} disabled>
            {t('auth.signin.ctaWithPartner', { partnerName: 'Facebook' })}
          </Button>
          <Message>
            <Trans
              i18nKey={'auth.fbButtonLoadErrorBbu'}
              components={{
                a: <Link to={signupBbuRoute} />,
              }}
            />
          </Message>
        </div>
      ) : (
        <div data-tooltip-id={'fb-sdk-error'}>
          <Button $fluid $variant={'facebook'} disabled>
            {t('auth.signin.ctaWithPartner', { partnerName: 'Facebook' })}
          </Button>
          <Tooltip id={'fb-sdk-error'}>{t('auth.fbButtonLoadError')}</Tooltip>
        </div>
      )}
    </>
  )
}
