import * as yup from 'yup'
import { MappedField, MappedFieldFormulaParam } from 'Nbee'

export const makeFieldMappingValidationSchema = () => {
  const formulaParamsSchema = yup.lazy((value: MappedFieldFormulaParam) => {
    if (value.type === 'field') {
      if (value.required) {
        return yup.object({
          values: yup
            .string()
            .nullable()
            .required('Formula is missing required Field')
            .test(
              'non-empty',
              'Formula is missing required Field',
              (val) => val !== ''
            ),
        })
      } else {
        return yup.object({
          values: yup.string().nullable(),
        })
      }
    } else if (value.type === 'int') {
      return yup.object({
        values: yup
          .string()
          .nullable()
          .matches(/^\d*$/, 'Invalid number in formula'),
      })
    } else {
      return yup.object()
    }
  }) as never

  const mappedItemSchema = yup.lazy((value: MappedField) => {
    if (value.destinationFieldType === 'formula') {
      return yup.object({
        formula: yup
          .object({
            id: yup.string().required('Select a formula'),
            params: yup.array().of(formulaParamsSchema),
          })
          .nullable(),
      })
    }

    if (value.isRequired && value.destinationFieldType === 'source') {
      return yup.object({
        sourceFieldId: yup.string().required('Required field'),
      })
    }

    if (value.isRequired && value.destinationFieldType === 'text') {
      return yup.object({
        destinationText: yup.string().required('Required field'),
      })
    }

    return yup.object()
  }) as never // https://github.com/jquense/yup/issues/1283

  return yup.object().shape({
    name: yup.string().required(),
    fieldsMapping: yup.array().of(mappedItemSchema),
  })
}
