import React, { useContext, useEffect } from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import {
  fromApiTestReponseToFormErrors,
  transformFormValuesToTestMappingRequest,
} from './SendTestLeadSubForm/utils'
import { useTranslation } from 'react-i18next'
import { useTestFieldsMapping } from '@app/api/testFieldsMapping'
import { FormikProps } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { ApiBridgeFieldDefinition, ApiErrorResponse } from 'BackendApi'
import { PanelPopupContext } from '@components/Panel'
import mappingErrorImage from '@assets/images/illustrations/field-mapping-test-error.svg'
import { PopupContentMappingError } from '@features/nbee/FieldsMappingForm/popupContent/PopupContentMappingError'
import { useUpdateBridge } from '@app/api/updateBridge'
import { transformBridgeFormValuesToApiSaveBridgeBody } from '@app/api/utils/bridge'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { parseApiError } from '@app/api/utils/error'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { useAppDispatch } from '@app/store/hooks'

interface Props {
  onSuccess: () => void
  formikProps: FormikProps<BridgeFormValues>
  scope: 'test' | 'save'
  isDisabledFromParent?: boolean
  isSavingFromParent?: boolean
  bridgeId: number
  destinationFields: ApiBridgeFieldDefinition[]
}
// We use this button to test mapping with API, before proceeding to next step
// next step (or scope) could be `test` or `save`.
// `test` is used for reaching screen to test with a new lead
// `save` is to handle the saving of the mapping (Save and Publish action)
// The look of the button (variant and text) is controlled by the `scope` prop.
// Once test is successful we run `onSuccess` callback to proceed to the next step/scope
export const ButtonTestBeforeProceeding: React.FC<Props> = ({
  onSuccess,
  formikProps,
  scope,
  isDisabledFromParent,
  isSavingFromParent,
  bridgeId,
  destinationFields,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const panelContext = useContext(PanelPopupContext)
  const formValues = formikProps.values
  const integrationId = formValues?.destination?.integrationId

  const { mutate: updateBridge, isLoading: isUpdatingBridge } =
    useUpdateBridge()

  const {
    mutate: testFieldsMapping,
    data: testFieldsMappingResult,
    isLoading: isTestingFieldsMapping,
    error: testFieldsMappingError,
  } = useTestFieldsMapping()

  useEffect(() => {
    formikProps.setSubmitting(isTestingFieldsMapping || isUpdatingBridge)
  }, [isTestingFieldsMapping || isUpdatingBridge])

  useEffect(() => {
    if (!panelContext || !panelContext.sendPopup) {
      return
    }

    // in case of error we open a popup to inform user about it
    // we can have an hard error like 400/500 but also when is 200
    // we will receive a list of fields that have not passed the test
    // so popup error needs to be triggered also if response is 200 and has data
    const response = testFieldsMappingResult?.data?.errors || []
    const someFieldsHaveError = response.length
    if (testFieldsMappingError || someFieldsHaveError) {
      // update form errors state
      formikProps.setErrors(
        fromApiTestReponseToFormErrors(destinationFields, response)
      )

      // show popup
      panelContext.sendPopup({
        content: <PopupContentMappingError fieldLabels={[]} />,
        dismissable: true,
        image: mappingErrorImage,
      })
    }
  }, [testFieldsMappingError])

  useEffect(() => {
    if (testFieldsMappingResult && scope === 'test') {
      const updateBridgeData = transformBridgeFormValuesToApiSaveBridgeBody(
        formValues,
        { step: 2 }
      )

      updateBridge(
        {
          bridgeData: updateBridgeData,
          bridgeId: `${bridgeId}`,
        },
        {
          onSuccess: () => {
            onSuccess() // setScreenState
          },
          onError: (error: ApiErrorResponse) => {
            // show popup
            panelContext &&
              panelContext.sendPopup &&
              panelContext.sendPopup({
                content: (
                  <InputFeedback
                    $status={{ error: parseApiError(error).message }}
                  />
                ),
                dismissable: true,
                image: mappingErrorImage,
              })
          },
        }
      )
    }

    if (testFieldsMappingResult && scope === 'save') {
      onSuccess() // updates bridge + publish
    }
  }, [testFieldsMappingResult])
  return (
    <Button
      // keep the type as button (also if it should be `submit`), this is because
      // we don't want a direct form submit, instead we manually run
      // formikProps.submitForm() on success
      type={'button'}
      $variant={scope === 'save' ? 'primary' : 'secondary'}
      disabled={
        isDisabledFromParent || isSavingFromParent || formikProps.isSubmitting
      }
      $loading={
        isSavingFromParent || isTestingFieldsMapping || isUpdatingBridge
      }
      onClick={() => {
        if (integrationId) {
          testFieldsMapping(
            {
              integrationId: integrationId,
              fieldsToTest: transformFormValuesToTestMappingRequest(
                formikProps.values.fieldsMapping
              ),
            },
            {
              onError: (apiError) => {
                const ErrorMessages =
                  apiError?.response?.data?.data?.errors?.map(
                    (item) => item.message
                  ) as string[]

                dispatch(
                  sendToast({
                    title: 'Error',
                    messages: ErrorMessages,
                    color: 'negative',
                  })
                )
              },
            }
          )
        }
      }}
    >
      {scope === 'save'
        ? t('nbee.fieldsMapping.saveAndPublish')
        : t('nbee.fieldsMapping.testFieldsMapping')}
    </Button>
  )
}
