import React from 'react'
import { EmailReceiptStyled } from '@features/nbee/EmailReceipt/styled'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { useField } from 'formik'
import { InputField } from '@components/Form/InputField'
import { useTranslation } from 'react-i18next'

interface Props {
  name: string
  disabled?: boolean
}

const { FieldGroup, FieldRow } = EmailReceiptStyled

export const FieldSubject: React.VFC<Props> = ({ name, disabled }) => {
  const { t } = useTranslation()
  const [field, meta] = useField<string[]>(name)

  return (
    <FieldGroup>
      <FieldRow>
        <label>{t('nbee.emailReceipt.labelSubject')}</label>
        <InputField
          {...field}
          type={'text'}
          placeholder={t('nbee.emailReceipt.placeholderSubject')}
          disabled={disabled}
        />
      </FieldRow>

      <FieldRow>
        <div />
        {meta.touched && meta.error ? (
          <InputFeedback
            $status={{
              error: Array.isArray(meta.error)
                ? meta.error.filter((msg) => !!msg).join(', ')
                : meta.error,
            }}
          />
        ) : null}
      </FieldRow>
    </FieldGroup>
  )
}
