import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import all from 'public/locales/en-US.json'

const resources = {
  en: {
    all: all,
  },
}

i18n.use(initReactI18next).init({
  resources: resources,
  lng: 'en',
  defaultNS: 'all',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

const w = window as any
w.showLocaleKeys = (showKeys: boolean) =>
  i18n.changeLanguage(showKeys ? 'cimode' : 'en')

export default i18n
